import dateFormat from 'dateformat'

export const loadTemplate = selector => document.importNode(document.querySelector(selector).content, true)

export const buildWarning = warningMessage => {
  var warningContent = loadTemplate('#liveagent-modal-warning-template')
  warningContent
    .querySelector('.liveagent-modal-warning-message')
    .insertAdjacentHTML('beforeend', '<h2>' + warningMessage + '</h2>')
  return warningContent
}

export const notImplemented = modal => {
  modal.info('This feature is not implemented yet.')
}

export const getCookie = name => {
  var cookiestring = RegExp('' + name + '[^;]+').exec(document.cookie)
  return unescape(cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '')
}

function padTime(i) {
  if (i < 10) {
    i = '0' + i
  } // add zero in front of numbers < 10
  return i
}

export const CallTimer = function() {
  this.value = ''
  this.startTime = new Date()
  this.seconds = 0

  this.startCallTimer = function() {
    var endTime = new Date()
    var timeDiff = endTime - this.startTime
    timeDiff /= 1000
    this.seconds = timeDiff
    this.value = secondsToHMS(timeDiff)
    this.timeout = setTimeout(this.startCallTimer.bind(this), 500)
  }
}

export const secondsToHMS = seconds => {
  let s = Math.round(seconds % 60)
  seconds = Math.floor(seconds / 60)
  let m = Math.round(seconds % 60)
  seconds = Math.floor(seconds / 60)
  let h = Math.round(seconds % 60)
  let ph = padTime(h)
  let pm = padTime(m)
  let ps = padTime(s)
  return (h ? ph + ':' : '') + pm + ':' + ps
}

export const UserTime = function(timeZone, dst) {
  this.timeZone = timeZone
  this.dst = dst
  this.value = 'unknown'
  this.shortValue = ''

  this.offsets = {
    NT: -3.5,
    AT: -4,
    ET: -5,
    CT: -6,
    MT: -7,
    PT: -8,
    AK: -9,
    HT: -10,
    AS: -11,
    CH: 10,
  }
  function getTZOffset() {
    if (this.timeZone) {
      if (this.dst) {
        return this.offsets[this.timeZone] + 1
      } else {
        return this.offsets[this.timeZone]
      }
    }
    return new Date().getTimezoneOffset() / -60
  }
  this.startClientTime = function() {
    var d = new Date()
    var utc = d.getTime() + d.getTimezoneOffset() * 60000

    var localTime = new Date(utc + 3600000 * getTZOffset.bind(this)())
    this.value = dateFormat(localTime, 'dddd\n mmmm dS, yyyy\n h:MM:ss TT')
    this.shortValue = dateFormat(localTime, 'HH:MM')
    this.timeout = setTimeout(this.startClientTime.bind(this), 500)
  }
}

export const formatPhoneNumber = number => {
  if (typeof number === 'undefined') {
    return 'unknown'
  }
  if (number.length == 10) {
    // (123) 456-7890
    return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10)
  }
  if (number.length == 11) {
    // 1-800-456-7890
    return (
      number.substring(0, 1) +
      '-' +
      number.substring(1, 4) +
      '-' +
      number.substring(4, 7) +
      '-' +
      number.substring(7, 11)
    )
  }
  return number
}

export const saveButtonLabel = config => {
  if (config.isSaving) {
    return 'Saving...'
  }
  if (config.error) {
    return 'Save Failed'
  }
  return 'Save'
}

export const checkValidConfigKey = key => {
  return /\W/g.test(key)
}

export const states = [
  { short: 'AB', name: 'Alberta', country: 'CA' },
  { short: 'BC', name: 'British Columbia', country: 'CA' },
  { short: 'MB', name: 'Manitoba', country: 'CA' },
  { short: 'NB', name: 'New Brunswick', country: 'CA' },
  {
    short: 'NL',
    name: 'Newfoundland and Labrador',
    country: 'CA',
    alt: ['Newfoundland', 'Labrador'],
  },
  {
    short: 'NF',
    name: 'Newfoundland and Labrador',
    country: 'CA',
    alt: ['Newfoundland', 'Labrador'],
  },
  { short: 'NS', name: 'Nova Scotia', country: 'CA' },
  { short: 'NU', name: 'Nunavut', country: 'CA' },
  { short: 'NT', name: 'Northwest Territories', country: 'CA' },
  { short: 'ON', name: 'Ontario', country: 'CA' },
  { short: 'PE', name: 'Prince Edward Island', country: 'CA' },
  { short: 'QC', name: 'Quebec', country: 'CA' },
  { short: 'PQ', name: 'Quebec', country: 'CA' },
  { short: 'SK', name: 'Saskatchewan', country: 'CA' },
  { short: 'YT', name: 'Yukon', country: 'CA' },
  { short: 'AL', name: 'Alabama', country: 'US' },
  { short: 'AK', name: 'Alaska', country: 'US' },
  { short: 'AZ', name: 'Arizona', country: 'US' },
  { short: 'AR', name: 'Arkansas', country: 'US' },
  { short: 'CA', name: 'California', country: 'US' },
  { short: 'CO', name: 'Colorado', country: 'US' },
  { short: 'CT', name: 'Connecticut', country: 'US' },
  { short: 'DC', name: 'District of Columbia', country: 'US' },
  { short: 'DE', name: 'Delaware', country: 'US' },
  { short: 'FL', name: 'Florida', country: 'US' },
  { short: 'GA', name: 'Georgia', country: 'US' },
  { short: 'HI', name: 'Hawaii', country: 'US' },
  { short: 'ID', name: 'Idaho', country: 'US' },
  { short: 'IL', name: 'Illinois', country: 'US' },
  { short: 'IN', name: 'Indiana', country: 'US' },
  { short: 'IA', name: 'Iowa', country: 'US' },
  { short: 'KS', name: 'Kansas', country: 'US' },
  { short: 'KY', name: 'Kentucky', country: 'US' },
  { short: 'LA', name: 'Louisiana', country: 'US' },
  { short: 'ME', name: 'Maine', country: 'US' },
  { short: 'MD', name: 'Maryland', country: 'US' },
  { short: 'MA', name: 'Massachusetts', country: 'US' },
  { short: 'MI', name: 'Michigan', country: 'US' },
  { short: 'MN', name: 'Minnesota', country: 'US' },
  { short: 'MS', name: 'Mississippi', country: 'US' },
  { short: 'MO', name: 'Missouri', country: 'US' },
  { short: 'MT', name: 'Montana', country: 'US' },
  { short: 'NE', name: 'Nebraska', country: 'US' },
  { short: 'NV', name: 'Nevada', country: 'US' },
  { short: 'NH', name: 'New Hampshire', country: 'US' },
  { short: 'NJ', name: 'New Jersey', country: 'US' },
  { short: 'NM', name: 'New Mexico', country: 'US' },
  { short: 'NY', name: 'New York', country: 'US' },
  { short: 'NC', name: 'North Carolina', country: 'US' },
  { short: 'ND', name: 'North Dakota', country: 'US' },
  { short: 'OH', name: 'Ohio', country: 'US' },
  { short: 'OK', name: 'Oklahoma', country: 'US' },
  { short: 'OR', name: 'Oregon', country: 'US' },
  { short: 'PA', name: 'Pennsylvania', country: 'US' },
  { short: 'RI', name: 'Rhode Island', country: 'US' },
  { short: 'SC', name: 'South Carolina', country: 'US' },
  { short: 'SD', name: 'South Dakota', country: 'US' },
  { short: 'TN', name: 'Tennessee', country: 'US' },
  { short: 'TX', name: 'Texas', country: 'US' },
  { short: 'UT', name: 'Utah', country: 'US' },
  { short: 'VT', name: 'Vermont', country: 'US' },
  { short: 'VA', name: 'Virginia', country: 'US' },
  { short: 'WA', name: 'Washington', country: 'US' },
  { short: 'WV', name: 'West Virginia', country: 'US' },
  { short: 'WI', name: 'Wisconsin', country: 'US' },
  { short: 'WY', name: 'Wyoming', country: 'US' },
  { short: 'AS', name: 'American Samoa', country: 'US' },
  { short: 'GU', name: 'Guam', country: 'US' },
  { short: 'MP', name: 'Northern Mariana Islands', country: 'US' },
  { short: 'PR', name: 'Puerto Rico', country: 'US' },
  { short: 'UM', name: 'United States Minor Outlying Islands', country: 'US' },
  { short: 'VI', name: 'Virgin Islands', country: 'US' },
]
export const extractLocationParts = locationStr => {
  for (let i = 0; i < states.length; i++) {
    if (locationStr.indexOf(states[i].name) >= 0) {
      let result = {}
      result.state = states[i].short
      result.country = states[i].country
      result.city = locationStr.replace(states[i].name, '').trim()
      return result
    }
  }
}

export const download = (filename, blob) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var URL = window.URL || window.webkitURL
    var downloadUrl = URL.createObjectURL(blob)

    if (filename) {
      // use HTML5 a[download] attribute to specify filename
      var a = document.createElement('a')
      // safari doesn't support this yet
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl
      } else {
        a.href = downloadUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
      }
    } else {
      window.location = downloadUrl
    }

    setTimeout(function() {
      URL.revokeObjectURL(downloadUrl)
    }, 100) // cleanup
  }
}

export default {
  loadTemplate,
  buildWarning,
  notImplemented,
  getCookie,
  UserTime,
  CallTimer,
  formatPhoneNumber,
  saveButtonLabel,
  checkValidConfigKey,
  extractLocationParts,
  states,
  dateFormat,
  secondsToHMS,
  download,
}
