/* eslint no-console: 0 */
import dateFormat from 'dateformat'

const debug = {
  // List of defined loggers (indexed by logger identifier). A value of true means
  // enabled, and false is disabled.
  enabled: {},
  levels: {
    0: 'CONSOLE',
    1: 'ALERT',
    2: 'CRITICAL',
    3: 'ERROR',
    4: 'WARNING',
    5: 'NOTICE',
    6: 'INFO',
    7: 'DEBUG',
  },
  level: 7,
}

export const log = function() {
  let now = new Date().getTime()
  var prefix = dateFormat(now, 'yy/mm/dd HH:MM:ss:l')
  if (arguments.length > 1 && 'number' === typeof arguments[0]) {
    if (debug.level >= arguments[0]) {
      var level = arguments[0]
      arguments[0] = prefix + ' ' + (debug.levels[level] ? debug.levels[level] : 'LOG') + ': '
      if (level > 4) {
        console.log.apply(null, arguments)
      } else {
        console.error.apply(null, arguments)
      }
    }
  } else {
    var a = Array.prototype.slice.call(arguments)
    a.unshift(prefix)
    console.error.apply(null, a)
  }
}
