var hiddenClass = 'liveagent-modal-hidden'

var modal = {}

var ui = {}

modal.open = function openModalDialog(modalType, content, callback, onload) {
  var newModal = {}

  var overlay = ui.overlay.cloneNode(true)
  var message = overlay.querySelector('#liveagent-modal-message')
  var ok = overlay.querySelector('#liveagent-modal-ok')
  var cancel = overlay.querySelector('#liveagent-modal-cancel')

  if (typeof content == 'string') {
    message.innerHTML = content
  } else if (content.nodeType) {
    message.appendChild(content)
  }

  if (modalType == 'warn') {
    ok.replaceChild(document.createTextNode('YES'), ok.lastChild)
    cancel.disabled = false
  } else if (modalType == 'confirm') {
    ok.replaceChild(document.createTextNode('OK'), ok.lastChild)
    cancel.replaceChild(document.createTextNode('CANCEL'), cancel.lastChild)
  } else {
    ok.replaceChild(document.createTextNode('OK'), ok.lastChild)
    cancel.disabled = true
  }

  newModal.overlay = overlay
  newModal.callback = callback
  newModal.onload = onload
  //    console.log(newModal);
  modal.show.call(newModal)
}

modal.show = function showModalDialog() {
  if (!this || !this.overlay) {
    return false
  }

  //    console.log('showing modal dialog');

  this.overlay.addEventListener('keyup', closeOrConfirm.bind(this))
  this.overlay.querySelector('#liveagent-modal-cancel').addEventListener('click', hideModal.bind(this.overlay))
  this.overlay.querySelector('#liveagent-modal-ok').addEventListener('click', modalConfirm.bind(this))

  document.querySelector('body').appendChild(this.overlay)

  window.setTimeout(
    function addModalElement() {
      this.overlay.classList.remove(hiddenClass)

      var inputs = this.overlay.querySelectorAll('input')
      if (inputs.length) {
        inputs[0].focus()
      } else {
        this.overlay.focus()
      }

      if (typeof this.onload === 'function') {
        this.onload()
      }
    }.bind(this),
    100
  )
}

function closeOrConfirm(event) {
  if (!this || !this.overlay || !event || !event.keyCode) {
    return
  } else if (event.keyCode === 27) {
    hideModal.call(this.overlay)
  } else if (event.keyCode === 13) {
    modalConfirm.call(this)
  }
}

function hideModal() {
  //    console.log('hiding modal dialog');

  var transitionDelay = parseFloat(window.getComputedStyle(this).getPropertyValue('transition-duration')) * 1000
  this.classList.add(hiddenClass)

  window.setTimeout(
    function removeModalElement() {
      document.querySelector('body').removeChild(this)
    }.bind(this),
    transitionDelay
  )
}

function modalConfirm() {
  if (typeof this.callback == 'function') {
    this.callback()
  }
  hideModal.call(this.overlay)
}

modal.ask = function ask(content, action, onload) {
  modal.open('warn', content, action, onload)
}

modal.info = function info(content, action, onload) {
  modal.open('info', content, action, onload)
}

modal.confirm = function confirm(content, action, onload) {
  modal.open('confirm', content, action, onload)
}

modal.init = function initializeModalDialogModule() {
  ui.overlay = document.createElement('div')
  ui.overlay.tabIndex = 90
  ui.overlay.classList.add('liveagent-modal')
  ui.overlay.classList.add(hiddenClass)

  ui.container = document.createElement('div')
  ui.container.id = 'liveagent-modal-container'

  ui.message = document.createElement('div')
  ui.message.id = 'liveagent-modal-message'
  ui.message.classList.add('liveagent-modal-message')

  ui.controls = document.createElement('footer')
  ui.controls.id = 'liveagent-modal-controls'

  ui.ok = document.createElement('button')
  ui.ok.id = 'liveagent-modal-ok'
  ui.ok.appendChild(document.createTextNode('OK'))
  ui.ok.tabIndex = 91

  ui.cancel = document.createElement('button')
  ui.cancel.id = 'liveagent-modal-cancel'
  ui.cancel.appendChild(document.createTextNode('NO'))
  ui.cancel.tabIndex = 91

  ui.controls.appendChild(ui.ok)
  ui.controls.appendChild(ui.cancel)

  ui.container.appendChild(ui.message)
  ui.container.appendChild(ui.controls)

  ui.overlay.appendChild(ui.container)
}

modal.init()

export default {
  ask: modal.ask,
  info: modal.info,
  confirm: modal.confirm,
  hide: hideModal,
}
