import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
	count: 0,
	showSidebar: false,
	liveAgent: {},
	mode: 'Safe',
	selectedFreeswitchServer: {},
	selectedSfaraServer: {},
	selectedPlacesServer: {},
	selectedVespaServer: {},
	selectedDatabaseServers: [],
	selectedDatabaseServerSourceDest: { source: {}, dest: {} },
	viizReportDownloading: false,
	viizReportError: false,
	db_query: '',
	db_query2: '',
	db_query_results: [],
	db_show: 'query',
	db_show_matching: true,
	call_stats: { ivr_calls: [], live_calls: [] },
	calls: [],
	ani_filter: '',
	dnis_filter: '',
	callType_filter: '',
	customer_filter: '',
	subcustomer_filter: '',
	agent_filter: '',
	queue_filter: '',
	callcenter_filter: '',
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
})
