// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
export const setShowSidebar = (state, value) => {
  state.showSidebar = value
}
export const setLiveAgent = (state, value) => {
  state.liveAgent = value
}
export const setMode = (state, value) => {
  state.mode = value
}

export const setServers = (state, value) => {
  state.liveAgent.admin.servers = value.map((server) => {
    try {
      server.extraObj = JSON.parse(server.extra)
    } catch (e) {
      server.extraObj = {}
    }
    try {
      server.privateObj = JSON.parse(server.private)
    } catch (e) {
      server.privateObj = {}
    }
    return server
  })
}

export const setCalls = (state, value) => {
  //value comes in built like {ivr_calls, live_calls, stats: {}}
  //combine the ivr_calls and live_calls into just "calls" for all processing beyond this point
  let all_calls = [...value.ivr_calls]
  value.live_calls.forEach((call) => {
    let index = all_calls.findIndex((c) => c.uuid === call.uuid)
    if (index > -1) {
      Object.assign(all_calls[index], call)
    } else {
      all_calls.unshift(call)
    }
  })
  state.calls = all_calls
  state.call_stats = value.stats
}

export const addCall = (state, value) => {
  let call = state.calls.find((call) => call.uuid === value.uuid)
  let call_stats_calls = value.server_type === 'ivr' ? state.call_stats.ivr_calls : state.call_stats.live_calls

  if (!call) {
    state.calls.unshift(value)
    let call_stats_server = call_stats_calls.find((server) => server.server_id === value.server_id)
    if (call_stats_server) {
      call_stats_server.count++
    }
  }
}

export const updateCall = (state, value) => {
  let call = state.calls.find((call) => call.uuid === value.uuid)
  let call_stats_calls = value.server_type === 'ivr' ? state.call_stats.ivr_calls : state.call_stats.live_calls

  if (call) {
    //vuex is reactive on object changes, and it appears that nested object changes are being ignored, thus replace the call object
    let index = state.calls.findIndex((c) => c.uuid === call.uuid)
    let new_call_object = { ...call }
    if (value.server_type === 'liveop') {
      value.live_uuid = value.uuid
      //don't overwrite existing uuid, which matters when the call first comes through ivr
      delete value.uuid
    }
    Object.assign(new_call_object, value)
    state.calls.splice(index, 1, new_call_object)
  } else {
    state.calls.unshift(value)
    let call_stats_server = call_stats_calls.find((server) => server.server_id === value.server_id)
    if (call_stats_server) {
      call_stats_server.count++
    }
  }
}

export const removeCall = (state, value) => {
  state.calls = state.calls.filter((call) => {
    let shouldKeepCall =
      call.uuid !== value.uuid &&
      call.autouuid !== value.uuid &&
      // filter calls that are longer than an hour
      call.timestamp > (new Date().getTime() - 1000 * 60 * 60) * 1000
    if (!shouldKeepCall) {
      let ivr_call_stats_server = state.call_stats.ivr_calls.find((server) => server.server_id === call.server_id)
      if (ivr_call_stats_server) {
        ivr_call_stats_server.count--
      }
      let live_call_stats_server = state.call_stats.live_calls.find((server) => server.server_id === call.server_id)
      if (live_call_stats_server) {
        live_call_stats_server.count--
      }
    }
    return shouldKeepCall
  })
}

export const updateMemoSearch = (state, payload) => {
  state.liveAgent.admin.memosearch = Object.assign({}, state.liveAgent.admin.memosearch, payload)
}

export const selectDatabaseServer = (state, payload) => {
  if (payload.select) {
    if (!state.selectedDatabaseServers.find((s) => s.rid === payload.server.rid)) {
      state.selectedDatabaseServers.push(payload.server)
    }
  } else {
    state.selectedDatabaseServers = state.selectedDatabaseServers.filter((s) => s.rid !== payload.server.rid)
  }
}

export const selectDatabaseServerForSourceDest = (state, value) => {
  if (state.selectedDatabaseServerSourceDest.source.rid) {
    if (state.selectedDatabaseServerSourceDest.source === value) {
      state.selectedDatabaseServerSourceDest.source = {}
    }
    state.selectedDatabaseServerSourceDest.dest = value
  } else {
    if (state.selectedDatabaseServerSourceDest.dest === value) {
      state.selectedDatabaseServerSourceDest.dest = {}
    } else {
      state.selectedDatabaseServerSourceDest.source = value
    }
  }
}

export const updateDBQuery = (state, value) => {
  state.db_query = value
}

export const updateDBQuery2 = (state, value) => {
  state.db_query2 = value
}

export const updateDBHideMatching = (state, value) => {
  state.db_show_matching = value
}

export const addQueryResult = (state, value) => {
  state.db_query_results.push(value)
}

export const setDB_show = (state, value) => {
  clear_db_query_results(state)
  state.db_show = value
}

export const clear_db_query_results = (state) => {
  state.db_query_results = []
}

export const selectFreeswitchServer = (state, value) => {
  state.selectedFreeswitchServer = value
}

export const selectSfaraServer = (state, value) => {
  state.selectedSfaraServer = value
}

export const selectPlacesServer = (state, value) => {
  state.selectedPlacesServer = value
}

export const selectVespaServer = (state, value) => {
  state.selectedVespaServer = value
}

export const setViizReportDownloading = (state, value) => {
  state.viizReportDownloading = value
}

export const setViizReportError = (state, value) => {
  state.viizReportError = value
}
