// getters are functions

export const evenOrOdd = state => (state.count % 2 === 0 ? 'even' : 'odd')

export const showSidebar = state => state.showSidebar

export const liveAgent = state => state.liveAgent

export const mode = state => state.mode

export const selectedFreeswitchServer = state => state.selectedFreeswitchServer

export const selectedSfaraServer = state => state.selectedSfaraServer

export const selectedPlacesServer = state => state.selectedPlacesServer

export const selectedVespaServer = state => state.selectedVespaServer

export const selectedDatabaseServers = state => state.selectedDatabaseServers

export const db_query_results = state => state.db_query_results

export const db_show = state => state.db_show

export const selectedDatabaseServerSourceDest = state => state.selectedDatabaseServerSourceDest

export const shouldShowModeSelector = state =>
	state.liveAgent &&
	state.liveAgent.session &&
	state.liveAgent.session.roles &&
	state.liveAgent.session.roles.find(role => role === 'sysadmin')

export const sortedEnvironments = state => {
	if (
		state.liveAgent &&
		state.liveAgent.session &&
		state.liveAgent.session.environments &&
		state.liveAgent.session.environments.list &&
		state.liveAgent.session.environments.list.length
	) {
		return state.liveAgent.session.environments.list.sort((a, b) => a.order - b.order)
	} else {
		return []
	}
}

export const server_types = state => {
	if (
		state.liveAgent &&
		state.liveAgent.session &&
		state.liveAgent.session.server_types &&
		state.liveAgent.session.server_types.list &&
		state.liveAgent.session.server_types.list.length
	) {
		return state.liveAgent.session.server_types.list
	} else {
		return []
	}
}

export const liveAgentServers = state => {
	if (state.liveAgent && state.liveAgent.admin && state.liveAgent.admin.servers) {
		return state.liveAgent.admin.servers.filter(server => server.type === 'liveagent')
	} else {
		return []
	}
}

export const freeswitchServers = state => {
	if (state.liveAgent && state.liveAgent.admin && state.liveAgent.admin.servers) {
		return state.liveAgent.admin.servers.filter(server => server.type === 'freeswitch')
	} else {
		return []
	}
}

export const databaseServers = state => {
	if (state.liveAgent && state.liveAgent.admin && state.liveAgent.admin.servers) {
		return state.liveAgent.admin.servers.filter(server => server.type === 'database')
	} else {
		return []
	}
}

export const sfaraServers = state => {
	if (state.liveAgent && state.liveAgent.admin && state.liveAgent.admin.servers) {
		return state.liveAgent.admin.servers.filter(server => server.type === 'sfara')
	} else {
		return []
	}
}

export const placesServers = state => {
	if (state.liveAgent && state.liveAgent.admin && state.liveAgent.admin.servers) {
		return state.liveAgent.admin.servers.filter(server => server.type === 'places')
	} else {
		return []
	}
}

export const iprelayServers = state => {
	if (state.liveAgent && state.liveAgent.admin && state.liveAgent.admin.servers) {
		return state.liveAgent.admin.servers.filter(server => server.type === 'iprelay')
	} else {
		return []
	}
}

export const sidebarLinks = state =>
	(state.liveAgent &&
		state.liveAgent.session &&
		state.liveAgent.session.config &&
		state.liveAgent.session.config.sidebarLinks) ||
	[]

export const viizReportDownloading = state => state.viizReportDownloading

export const viizReportError = state => state.viizReportError

export const calls = state => state.calls

export const call_stats = state => state.call_stats
