// actions are functions that causes side effects and can involve
// asynchronous operations.
import { log } from '../lib/logger'

const parseResponse = res => {
	let parsedResponse
	try {
		parsedResponse = JSON.parse(res)
	} catch (e) {
		log(3, 'malformed api response')
	}
	return parsedResponse
}

export const action = ({ commit }) => commit('action')

export const getServers = ({ commit }) => {
	let xhr = new XMLHttpRequest()
	xhr.open('GET', '/api/servers/')
	xhr.onload = function buildServerList() {
		if (xhr.status === 200) {
			commit('setServers', parseResponse(xhr.responseText))
		} else {
			log(3, 'failed to get list of servers')
		}
	}
	xhr.send()
}

export const getCalls = ({ commit }) => {
	let xhr = new XMLHttpRequest()
	xhr.open('GET', '/api/calls')
	xhr.onload = function buildCallsList() {
		if (xhr.status === 200) {
			commit('setCalls', parseResponse(xhr.responseText))
		} else {
			log(3, 'failed to get list of calls')
		}
	}
	xhr.send()
}

export const runQuery = ({ commit, state }) => {
	commit('clear_db_query_results')
	state.selectedDatabaseServers.forEach(server => {
		let xhr = new XMLHttpRequest()
		xhr.open('POST', '/api/db_query/')
		xhr.onload = function buildServerList() {
			if (xhr.status === 200) {
				commit('addQueryResult', parseResponse(xhr.responseText))
			} else {
				log(3, 'failed to run query')
			}
		}
		xhr.setRequestHeader('content-type', 'application/json')
		xhr.send(JSON.stringify({ server_id: server.rid, query: state.db_query }))
	})
}

export const runCompare = ({ commit, state }) => {
	commit('clear_db_query_results')
	;[state.selectedDatabaseServerSourceDest.source, state.selectedDatabaseServerSourceDest.dest].forEach(
		(server, index) => {
			let xhr = new XMLHttpRequest()
			xhr.open('POST', '/api/db_query/')
			xhr.onload = function buildServerList() {
				if (xhr.status === 200) {
					commit('addQueryResult', parseResponse(xhr.responseText))
				} else {
					log(3, 'failed to run query')
				}
			}
			xhr.setRequestHeader('content-type', 'application/json')
			xhr.send(
				JSON.stringify({
					server_id: server.rid,
					query: index ? state.db_query2 || state.db_query : state.db_query,
				})
			)
		}
	)
}
export const runMemoSearch = ({ commit, state }, { server }) => {
	let path = ''
	if (state.liveAgent.admin.memosearch.uuid) {
		path = '?uuid=' + encodeURIComponent(state.liveAgent.admin.memosearch.uuid)
	} else {
		path =
			'?from=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.from_date || '') +
			'&to=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.to_date || '') +
			'&agent=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.agent || '') +
			'&dnis=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.dnis || '') +
			'&notes=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.notes || '') +
			'&callType=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.callType || '') +
			'&queue=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.queue || '') +
			'&ani=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.ani || '') +
			'&dest=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.dest || '') +
			'&cic=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.cic || '') +
			'&includeAbandonedCalls=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.includeAbandonedCalls || '') +
			(state.liveAgent.admin.memosearch.dial_stay?'&dialStay=':'') +
			encodeURIComponent(state.liveAgent.admin.memosearch.dial_stay || '') +
			(state.liveAgent.admin.memosearch.dial_caller?'&dialCaller=':'') +
			encodeURIComponent(state.liveAgent.admin.memosearch.dial_caller || '') +
			'&transfer=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.transfer || '') +
			'&callArrivalType=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.call_arrival_type || '') +
			(state.liveAgent.admin.memosearch.called_answered?'&calledAnswered=':'') +
			encodeURIComponent(state.liveAgent.admin.memosearch.called_answered || '') +
			'&outboundCall=' +
			encodeURIComponent(state.liveAgent.admin.memosearch.outbound_call || '') +
      '&maxResults=' +
      encodeURIComponent(state.liveAgent.admin.memosearch.max_results || '')
	}
  let maxDays = 7
  let to_date = new Date(state.liveAgent.admin.memosearch.to_date)
  let from_date = new Date(state.liveAgent.admin.memosearch.from_date)
  if (to_date - from_date > maxDays * 24 * 60 * 60 * 1000) {
    commit('updateMemoSearch', {
      error: `Selected time span must be less than ${maxDays} days`,
		})
		return
	}
	let xhr = new XMLHttpRequest()
	xhr.open('POST', '/api/memosearch')
	xhr.onload = function() {
		if (xhr.status === 200) {
			let parsedServerResponse = parseResponse(xhr.responseText)
			parsedServerResponse.results = parsedServerResponse.results.map(call => {
				try {
					call.extra = JSON.parse(call.extra)
				} catch (e) {
					state.liveAgent.log(3, 'Failed to parse call details extra')
					call.extra = {}
				}
				return call
			})
			commit('updateMemoSearch', {
				error: false,
				response: parsedServerResponse,
				lastServer: server,
			})
		} else {
			state.liveAgent.log(3, 'failed to get query')
			commit('updateMemoSearch', {
				error: 'Server Error: Unable to submit search query',
			})
		}
		server.memoSearchFetching = false
	}

	xhr.setRequestHeader('content-type', 'application/json')
	xhr.send(
		JSON.stringify({
			server_id: server.rid,
			options: {
				timeout: 60000 * 5,
				path: '/api/memosearch/' + path,
				method: 'POST',
			},
		})
	)
}
