<template>
  <header :class="['self_clear', 'viiz-header', { 'header--background-red': mode === 'Maintenance' }]">
    <img
      :class="[
        'header-logo',
        {
          rotate: liveAgent.operator && liveAgent.operator.currentCall && liveAgent.operator.currentCall.loading,
        },
      ]"
      src="/res/img/logo.png"
    />
    <span> {{ liveAgent.title ? liveAgent.title : 'Viiz Admin' }} </span>

    <span v-if="shouldShowModeSelector" :class="['dropdown', { open: activeModeSelector }]">
      <button
        id="dropdownMenuButton"
        v-click-outside="closeModeSelector"
        class="btn btn-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click.stop="openModeSelector"
      >
        {{ mode }} mode
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li class="clickable dropdown-item padded-half-rem" @click="setMode('Safe')">Safe mode</li>
        <li class="clickable dropdown-item padded-half-rem" @click="setMode('Maintenance')">Maintenance mode</li>
      </ul>
    </span>

    <transition name="fadebutton"
      ><button
        v-if="shouldShowSIPError"
        id="liveagent-status-sip"
        tabindex="100"
        class="button--red"
        title="SIP ERROR"
        @click="handleSipErrorClick"
      >
        !
      </button></transition
    >
    <transition name="fadebutton"
      ><button
        v-if="shouldShowSessionError"
        id="liveagent-status-socket"
        tabindex="100"
        class="button--red"
        title="SOCKET ERROR"
        @click="handleSocketErrorClick"
      >
        !
      </button></transition
    >
    <transition name="fadebutton"
      ><button
        v-if="shouldShowLogoffButton"
        id="liveagent-operator-controls-profile"
        tabindex="100"
        title="Profile"
        @click="handleProfileClick"
      >
        <img src="/res/img/149-cog.svg" /></button
    ></transition>
    <transition name="fadebutton"
      ><button
        v-if="shouldShowLogoffButton"
        id="liveagent-operator-controls-logoff"
        tabindex="100"
        title="LOG OUT"
        @click="handleLogOff"
      >
        X
      </button></transition
    >
  </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ViizHeader',
  data() {
    return {
      activeModeSelector: false,
    }
  },
  computed: {
    ...mapGetters(['liveAgent', 'mode', 'shouldShowModeSelector']),
    shouldShowSIPError: function() {
      return !!(this.liveAgent && this.liveAgent.operator && this.liveAgent.SIP && !this.liveAgent.SIP.connected)
    },
    shouldShowSessionError: function() {
      return !!(this.liveAgent && this.liveAgent.session && !this.liveAgent.session.socket.connected)
    },
    shouldShowLogoffButton: function() {
      return (
        !!(this.liveAgent && this.liveAgent.session && this.liveAgent.session.id && !this.liveAgent.operator) ||
        (this.liveAgent.operator && !(this.liveAgent.operator.currentCall && this.liveAgent.operator.currentCall.show))
      )
    },
  },
  watch: {
    'liveAgent.session.server_build': function() {
      if (this.liveAgent.session.server_build && this.liveAgent.session.build !== this.liveAgent.session.server_build) {
        if (this.liveAgent.operator) {
          if (this.liveAgent.operator.idle) {
            document.location.hash = '/autoreload/' + this.liveAgent.operator.status
          } else {
            return
          }
        }
        window.location.reload(false)
      }
    },
  },
  mounted: function() {
    this.liveAgent.mousetrap.bind('x x x', () => {
      if (this.shouldShowLogoffButton) {
        this.handleLogOff()
      }
      return false
    })
  },
  methods: {
    ...mapMutations(['setMode']),
    handleSipErrorClick: function() {
      this.liveAgent.modal.info(this.liveAgent.common.buildWarning('SIP Service Unavailable'))
    },
    handleSocketErrorClick: function() {
      this.liveAgent.modal.info(this.liveAgent.common.buildWarning('Web Socket Disconnected'))
    },
    handleProfileClick: function() {
      this.$router.push('/admin/profile')
    },
    handleLogOff: function() {
      if (this.liveAgent.SIP && this.liveAgent.SIP.ua) {
        this.liveAgent.SIP.ua.stop()
      }
      this.liveAgent.session.end('loggedoff')
    },
    openModeSelector: function() {
      this.activeModeSelector = true
    },
    closeModeSelector: function() {
      this.activeModeSelector = false
    },
  },
}
</script>
